import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { deleteExpense, getExpense } from "../../constant/expense";
import { useNavigate, useParams } from "react-router-dom";
import {
  Skeleton,
  Descriptions,
  Typography,
  Card,
  Flex,
  Button,
  Popconfirm,
  message,
} from "antd";
import { MdOutlineEdit, MdOutlineKeyboardBackspace } from "react-icons/md";
import { LuTrash2 } from "react-icons/lu";
import UpdateExpense from "./create";

const { Title } = Typography;

const SingleGet = () => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const navigation = useNavigate();

  const { data, isPending } = useQuery({
    queryKey: ["EXPENSE_DETAIL", id],
    queryFn: () => getExpense(id),
  });

  const { mutate, isPending: mutationPending } = useMutation({
    mutationFn: (payload) => deleteExpense(payload),
    onSuccess: (data) => {
      message.success("Successfully deleted!");
      navigation("/");
    },
    onError: (err) => {
      message.error("Something went wrong.");
    },
  });

  if (isPending)
    return (
      <div className="px-4">
        <Card>
          <Skeleton active />
        </Card>
      </div>
    );

  return (
    <>
      <div className="px-4">
        <Flex justify="space-between">
          <Title level={3}>Expense Details</Title>{" "}
          <Flex gap={4}>
            <Popconfirm
              title="Delete the expense"
              description="Are you sure to delete this task?"
              onConfirm={() => {
                if (id) {
                  mutate(id);
                }
              }}
              okText="Yes"
              cancelText="No"
              placement="left"
              disabled={mutationPending}
            >
              <Button
                type="primary"
                danger
                shape="circle"
                icon={<LuTrash2 />}
              />
            </Popconfirm>
            <Button
              type="primary"
              shape="circle"
              icon={<MdOutlineEdit />}
              onClick={() => {
                setIsOpen(true);
              }}
            />
            <Button
              type="primary"
              shape="circle"
              color="default"
              icon={<MdOutlineKeyboardBackspace />}
              onClick={(e) => {
                navigation("/");
              }}
            />
          </Flex>
        </Flex>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Amount">₹ {data.amount}</Descriptions.Item>
          <Descriptions.Item label="Category">
            {data.category.name}
          </Descriptions.Item>
          <Descriptions.Item label="Date">
            {new Date(data.date).toLocaleDateString()}
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            {data.description}
          </Descriptions.Item>
          {data?.note?.trim().length ? (
            <Descriptions.Item label="Note">{data.note}</Descriptions.Item>
          ) : null}
          <Descriptions.Item label="Created By">
            {data.user.name}
          </Descriptions.Item>
        </Descriptions>
      </div>
      {data && (
        <UpdateExpense
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          expId={data._id}
          selectedExpense={data}
        />
      )}
    </>
  );
};

export default SingleGet;
