import React from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import ListSkeleton from "../ui/list_skeleton";
import { Expense } from "./single_expense";
import { getExpenses } from "../../constant/expense";

const groupExpensesByDate = (expenses) => {
  const groupedExpenses = {};
  expenses.forEach((expense) => {
    const dateKey = moment(expense.date).format("D MMM");
    if (!groupedExpenses[dateKey]) {
      groupedExpenses[dateKey] = [];
    }
    groupedExpenses[dateKey].push(expense);
  });
  return groupedExpenses;
};

const totalAmountOfTheMonth = (expenses) => {
  const currentMonthExpenses = expenses.filter(
    (expense) => moment(expense.date).month() === moment().month()
  );
  return currentMonthExpenses.reduce(
    (total, expense) => total + expense.amount,
    0
  );
};

const totalAmountOfTheDay = (expenses) => {
  return expenses.reduce((total, expense) => total + expense.amount, 0);
};

const GetExpense = () => {
  const { data: expenses = [], isLoading } = useQuery({
    queryKey: ["ALL_EXPENSES"],
    queryFn: () => getExpenses(),
  });

  const expensesByDate = groupExpensesByDate(expenses);

  return (
    <>
      <div className="flex items-center justify-between mx-4 border p-4 rounded font-semibold text-lg overflow-hidden bg-muted blurred-bg">
        <h2>{moment().format("MMMM YYYY")}</h2>
        <h2>Total: ₹{totalAmountOfTheMonth(expenses).toFixed(2)}</h2>
      </div>
      <div>
        {isLoading ? (
          <ListSkeleton length={4} moreClasses="pt-4 px-4" />
        ) : (
          <div className="p-4 flex flex-col gap-4">
            {Object.entries(expensesByDate).map(([date, expenses]) => (
              <div key={date} className="border rounded overflow-hidden">
                <h3 className="flex items-center justify-between p-4 bg-muted text-foreground">
                  <strong>{moment(date).format("DD MMMM")}</strong>
                  <strong>Total: ₹ {totalAmountOfTheDay(expenses)}</strong>
                </h3>
                <ul className="flex gap-2 flex-col bg-white p-4">
                  {expenses.map((expense, i) => (
                    <Expense key={expense._id} expense={expense} i={i} />
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default GetExpense;
