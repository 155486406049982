import { useMutation } from "@tanstack/react-query";
import { Button, Flex, Form, Input, message, Modal } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { useAuth } from "../../store/contexts/auth";
import { updateCategory } from "../../api/category.service";

export const AddEditCategory = ({
  openCategoryModal,
  setOpenCategoryModal,
}) => {
  const { token } = useAuth();

  const [category, setCategory] = useState("");
  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => updateCategory(token, payload),
    onSuccess: (data) => {
      message.success("Successfully added category.");
      setOpenCategoryModal(false);
    },
    onError: (err) => {
      message.error("Something went wrong.");
    },
  });

  return (
    <Modal
      title="Add categories"
      open={openCategoryModal}
      onCancel={() => {
        setOpenCategoryModal(false);
      }}
      footer={
        <Flex justify="flex-end" gap={8}>
          <Button
            type="default"
            onClick={() => {
              setOpenCategoryModal(false);
            }}
          >
            Close
          </Button>
          <Button
            type="primary"
            className={classNames({
              "disabled-btn": isPending,
            })}
            disabled={isPending}
            onClick={() => {
              mutate({ name: category });
            }}
          >
            {isPending ? "Updating..." : "Update"}
          </Button>
        </Flex>
      }
    >
      <Form layout="vertical">
        <Form.Item label="Category Name">
          <Input
            placeholder="Commute..."
            value={category}
            onChange={(ev) => {
              setCategory(ev.target.value);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
