import React, { useEffect } from "react";
import { Drawer, Form, Input, Select, Button, message } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Axios from "../../config/axios";
import { createExpense, editExpense } from "../../constant/expense";

const fetchCategories = async () => {
  const response = await Axios.get("/categories");
  return response.data;
};

const CreateExpense = ({ isOpen, setIsOpen, expId, selectedExpense }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data: categories = [] } = useQuery({
    queryKey: ["CATEGORIES"],
    queryFn: fetchCategories,
  });

  const { mutate: createMutation, isPending: createPending } = useMutation({
    mutationFn: (payload) => createExpense(payload),
    onSuccess: () => {
      message.success("Expense successfully created.");
      queryClient.refetchQueries({ queryKey: ["ALL_EXPENSES"] });
      form.resetFields();
      setIsOpen(false);
    },
    onError: (error) => {
      message.error(error.response?.data?.error || "Something went wrong.");
    },
  });

  const { mutate: editMutation, isPending: editPending } = useMutation({
    mutationFn: (payload) => editExpense(payload, expId),
    onSuccess: () => {
      message.success("Expense successfully updated.");
      queryClient.invalidateQueries({
        queryKey: ["EXPENSE_DETAIL", expId],
      });
      setIsOpen(false);
    },
    onError: (error) => {
      message.error(error.response?.data?.error || "Something went wrong.");
    },
  });

  useEffect(() => {
    if (selectedExpense) {
      form.setFieldsValue({
        description: selectedExpense.description,
        amount: selectedExpense.amount,
        category: selectedExpense.category._id,
        note: selectedExpense.note,
        date: selectedExpense.date?.slice(0, 10),
      });
    } else {
      form.resetFields();
    }
  }, [selectedExpense, form]);

  const handleFormSubmit = (values) => {
    if (expId) {
      editMutation(values);
      return;
    }
    createMutation(values);
  };

  return (
    <Drawer
      title={expId ? "Edit Expense" : "Create a new expense"}
      placement="right"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      width={400}
    >
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please enter a description" }]}
        >
          <Input placeholder="Expense name or detail" />
        </Form.Item>

        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: "Please enter an amount" }]}
        >
          <Input type="number" placeholder="Amount spent" />
        </Form.Item>

        <Form.Item
          label="Category"
          name="category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select placeholder="Select a category">
            {categories.map((cat) => (
              <Select.Option key={cat._id} value={cat._id}>
                {cat.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Note (Optional)" name="note">
          <Input.TextArea placeholder="Extra details" />
        </Form.Item>

        <Form.Item label="Date" name="date">
          <Input type="date" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={createPending || editPending}
            block
            disabled={createPending || editPending}
          >
            {createPending
              ? expId
                ? "UPDATING..."
                : "CREATING..."
              : expId
              ? "UPDATE EXPENSE"
              : "CREATE EXPENSE"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateExpense;
