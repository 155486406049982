import React from "react";
import { Route, Routes } from "react-router-dom";
import Register from "./auth/Register";
import Login from "./auth/Login";
import Dashboard from "./dashboard";
import { PrivateRoute } from "./private/Private";
import SingleGet from "./expence_tracker/single_get";
import Note from "./note";
import Profile from "./auth/profile";
import Stats from "./stats";
import History from "./history";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/expense/:id"
        element={
          <PrivateRoute>
            <SingleGet />
          </PrivateRoute>
        }
      />
      <Route
        path="/note"
        element={
          <PrivateRoute>
            <Note />
          </PrivateRoute>
        }
      />
      <Route
        path="/auth"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />{" "}
      <Route
        path="/stats"
        element={
          <PrivateRoute>
            <Stats />
          </PrivateRoute>
        }
      />{" "}
      <Route
        path="/history"
        element={
          <PrivateRoute>
            <History />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRouter;
