import { useNavigate } from "react-router-dom";

export const Expense = ({ expense, i }) => {
  const navigate = useNavigate();

  return (
    <li className="flex gap-2 items-center">
      <span>{i + 1}.</span>
      <div className="flex items-center justify-between gap-4 w-full">
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate(`/expense/${expense._id}`);
          }}
        >
          {expense.description.length > 20
            ? `${expense.description.substring(0, 20)} ...`
            : expense.description}
        </span>{" "}
        <span>₹ {expense.amount}</span>
      </div>
    </li>
  );
};
